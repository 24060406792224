import img_1 from '/public/Images/New_Pic4.jpeg';
import img_2 from '/public/Images/Music.jpeg';
import img_3 from '/public/Images/NFT.jpeg';
import img_4 from '/public/Images/SXSW.jpeg';

import img2 from '../assets/images/icon/download.png';

export const dataDocument = [
  {
    id: 1,
    img: img_1,
    title: "WHITE PAPER",
    subtitle: "VIZION PROTOCOL WHITE PAPER",
    link: "https://drive.google.com/file/d/1JGfpcuLGtRqcGyBfx-olV0iEKvoVFiMQ/view?usp=drivesdk",
    text: "LINK",
    imgStyle: {
      height: "220px",
      width: "190px",
    },
  },
  {
    id: 2,
    img: img_2,
    title: "ONE PAGER",
    subtitle: "MUSIC FESTIVAL ",
    classAction: "bg-2",
    link: "https://www.instagram.com/p/Cz6RarJM4ik/?igsh=MWE4NWppdnFlcjY1Mw",
    text: "LINK",
    imgStyle: {
      height: "200px",
      width: "150px",
    },
  },
  {
    id: 3,
    img: img_3,
    title: "PRIVACY POLICY",
    subtitle: "NFT LAUNCH",
    classAction: "bg-3",
    link: "https://thelatestblock.com/nft-backed-horror-movie-wins-prestigious-award",
    text: "LINK",
    imgStyle: {
      height: "200px",
      width: "150px",
    },
  },
  {
    id: 4,
    img: img_4,
    title: "TERM OF COIN SALES",
    subtitle: "SXSW AWARD WINNER ",
    classAction: "bg-4",
    link: "https://www.sho.com/titles/3523681/bitch-ass",
    text: "LINK",
    imgStyle: {
      height: "200px",
      width: "150px",
    },
  },

  {
    id: 5,
    img: img2,
    title: "ONE PAPER",
  },
  {
    id: 6,
    img: img2,
    title: "WHITE PAPER",
  },
  {
    id: 7,
    img: img2,
    title: "PRIVACY POLICY",
  },
  {
    id: 8,
    img: img2,
    title: "TERM OF COIN SALES",
  },

  {
    id: 9,
    title: "White Paper",
    text: "LINK",
  },
  {
    id: 10,
    title: "One Paper",
    text: "LINK",
  },
  {
    id: 11,
    title: "Privacy Policy",
    text: "LINK",
  },
  {
    id: 12,
    title: "Term Of Sales",
    text: "LINK",
  },
];

export default dataDocument;