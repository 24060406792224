export const dataContact = [
  {
    id: 1,
    classAction: "icon-twitter",
    content: "Join Us on X",
    link: "https://twitter.com/viziontoken",
  },
  {
    id: 2,
    classAction: "icon-reddit",
    content: "Join Us on Discord",
    link: "https://discord.gg/E9wFKsTtnw",
  },
  {
    id: 3,
    classAction: "icon-telegram",
    content: "Join us on Telegram",
    link: "https://t.me/vizionhyperoom",
  },
];

export default dataContact;