
<template>
    <section class="token-details style-1" id="token">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="block-text center">
                        <h3>{{ title }}</h3>
                        <p class="mt-12">{{ subtitle }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mt-25">
                <div class="col-md-6">
                    <div class="infomation" data-aos="fade-up" data-aos-duration="800">
                        <ul class="info-list">
                            <li :class="item.classAction" v-for="item in dataTokendetails.slice(0, 7)" :key="item.id">
                                <h5>{{ item.title }}</h5>
                                <p class="fs-17">{{ item.desc }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6" style="margin-top: 48px;">
                    <div class="token-details__image">
                        <!-- <img src='/Images/New_Pic3.png' alt="Image" /> -->
                        <iframe id="dextools-widget" title="DEXTools Trading Chart" style="width: 100%" height="400"
                            X-Frame-Options="strict"
                            src="https://www.dextools.io/widget-chart/en/solana/pe-light/DPnLq2UwSCGhY1SJQzRdTRnWTTUKC75jcm4iiAv7Jf4k?theme=light&chartType=2&chartResolution=30&drawingToolbars=false"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="button" style="display: flex; justify-content: center; align-items: center;">
            <a class="btn-action style-1" style="max-width: fit-content;"
             href="https://raydium.io/swap/?outputCurrency=4giiLHQPdcuFnVcuBF7wpmfU88EXDdToJqBP8dfpSjtA"
                rel="noopener noreferrer">
                <span>BUY $VIZION
                </span>
                <span class="icon-arrow"></span>
            </a>
            <!-- <router-link class="btn-action style-1" style="max-width: fit-content;"
                to="https://raydium.io/swap/?outputCurrency=4giiLHQPdcuFnVcuBF7wpmfU88EXDdToJqBP8dfpSjtA">
                <span>Secure My ViZion Tokens sign-up
                </span>
                <span class="icon-arrow"></span></router-link> -->
        </div>
    </section>
</template>

<script>

import "./styles.scss";
import { dataTokendetails } from '../../fake-data/data-tokendeials';

export default {
    name: "Feature",
    data() {
        return {
            dataTokendetails,
            title: 'Utility Token Info',
            subtitle: 'ViZion Protocol is at the forefront of the blockchain innovation, transforming the music, film, and entertainment industry. Our Eco-System, built on the powerful SOLANA blockchain, is designed to revolutionize how these industries operate.',
        }
    }
};
</script>
            

<style>
.token-details__image {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 0;
    margin: unset !important;
}

.token-details__image img {
    width: 100%;
    height: auto;
}
</style>
            