import img1 from '../assets/images/icon/Vector.png';
import img2 from '../assets/images/icon/Vector2.png';
import img3 from '../assets/images/icon/Vector3.png';


import img4 from '../assets/images/item/item-banner-1.png';
import img5 from '../assets/images/item/item-banner-2.png';
import img6 from '../assets/images/item/item-banner-3.png';
import img7 from '../assets/images/item/item-banner-4.png';
import img8 from '../assets/images/item/item-banner-5.png';
import img9 from '../assets/images/item/item-banner-6.png';
import img10 from '../assets/images/item/item-banner-7.png';

import img11 from '../assets/images/icon/icon5.svg';
import img12 from '../assets/images/icon/icon6.svg';
import img13 from '../assets/images/icon/icon7.svg';
export const dataAbout = [
    {
        id: 1,
        classAction: 'icon-users',
        title: 'First To Market Utility Platform',
        content: 'ViZion aims to be the global leader of utility on blockchain by providing services, events, NFTs, and creating unforgettable crypto experiences thru this revolutionary technology.',
    },
    {
        id: 2,
        classAction: 'icon-internet',
        title: 'Building a Global Brand',
        content: 'Established in 2021, our core mission has always been to cultivate trust and provide unwavering support to our community from the outset. With a track record of over 10 events, 2 music festivals, and a feature film showcased on Showtime, our company has been diligently laying the groundwork behind the scenes. This preparation paves the way for the exciting launch of our ecosystem, marking a new chapter in our journey.',
    },
    {
        id: 3,
        classAction: 'icon-users',
        title: 'A Utility Based Eco-System',
        content: `ViZion is set to forge an ecosystem that not only upholds the value of our token but also offers unmatched utility and security for its holders. Our ambition is to be a pillar of support for content creators, uniting a diverse array of talents - including Producers, Directors, Writers, Actors, Musicians, Photographers, and Artists. We aim to assist in financing, funding, and distributing their work through our accessible platform, thereby nurturing a thriving community of creativity and collaboration.`,
    },
    {
        id: 4,
        classAction: 'icon-vuesax',
        title: 'Sercure Storage',
        content: 'The platform helps investors to make easy to purchase and sell their tokens',
    },
    {
        id: 5,
        classAction: 'icon-startup',
        title: 'Investment Projects',
        content: 'The platform helps investors to make easy to purchase and sell their tokens',
    },
    {
        id: 6,
        classAction: 'icon-card',
        title: 'Credit Card Use',
        content: 'The platform helps investors to make easy to purchase and sell their tokens.',
    },
    {
        id: 7,
        img: img1,
        title: 'Proven solutions',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget diam, euismod nulla a.',
    },
    {
        id: 8,
        img: img2,
        title: 'No financial Borders',
        content: 'Elementum mauris in et at iaculis orci ut netus. Tellus pretium lorem nibh donec.',
    },
    {
        id: 9,
        img: img3,
        title: 'Transformative Technologies',
        content: 'Elementum mauris in et at iaculis orci ut netus. Tellus pretium lorem nibh donec. Euismod nulla a e.',
    },

    {
        id: 10,
        classAction: 'icon-vuesax',
        title: 'Sercure Storage',
        content: 'The platform helps investors to make easy to purchase and sell their tokens',
    },
    {
        id: 11,
        classAction: 'icon-startup',
        title: 'Investment Projects',
        content: 'The platform helps investors to make easy to purchase and sell their tokens',
    },
    {
        id: 12,
        classAction: 'icon-calendar',
        title: 'Planing',
        content: 'The platform helps investors to make easy to purchase and sell their tokens.',
    },
    {
        id: 13,
        classAction: 'icon-mobile',
        title: 'Mobile App',
        content: 'The process of taking into account the collective opinion of a group.',
    },
    {
        id: 14,
        classAction: 'icon-convert-card',
        title: 'Exchange Storage',
        content: 'The system pay a bonus for excillent individuals conveniently.',
    },
    {
        id: 15,
        classAction: 'icon-card',
        title: 'Credit Card Use',
        content: 'The platform helps investors to make easy to purchase and sell their tokens.',
    },

    {
        id: 16,
        classAction: 'icon-buy',
        content: 'Buy and sell items easily',
    },
    {
        id: 17,
        classAction: 'icon-create',
        content: 'Create collections & earn rewards',
    },
    {
        id: 18,
        classAction: 'icon-chase',
        content: 'Chase limited edition ICOLand',
    },

    {
        id: 19,
        img: img4,
    },
    {
        id: 20,
        img: img5,
    },
    {
        id: 21,
        img: img6,
    },
    {
        id: 22,
        img: img7,
    },
    {
        id: 23,
        img: img8,
    },
    {
        id: 24,
        img: img9,
    },
    {
        id: 25,
        img: img10,
    },

    {
        id: 26,
        number: '01',
        title: 'Build Fast, Low Cost, Solidity-Compatible DApps',
        text: 'Launch Ethereum dApps that confirm transactions instantly and process thousands of transactions far beyond any decentralized blockchain platform today.',
    },
    {
        id: 27,
        number: '02',
        title: 'Launch Customized Blockchains, Private & Public',
        text: 'Deploy blockchains that fit your own application needs. Build your own virtual machine and dictate exactly how the blockchain should operate.',
    },
    {
        id: 28,
        number: '03',
        title: 'Scale To Millions Of Validators With Minimal Hardware',
        text: 'Stake, or lock up, your AVAX to help process transactions and further secure the platform–providing security guarantees well-above the 51% standard.',
    },

    {
        id: 29,
        img: img11,
        title: 'Proven solutions',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget diam, euismod nulla a.',
    },
    {
        id: 30,
        img: img12,
        title: 'No financial Borders',
        content: 'Elementum mauris in et at iaculis orci ut netus. Tellus pretium lorem nibh donec.',
    },
    {
        id: 31,
        img: img13,
        title: 'Transformative Technologies',
        content: 'Elementum mauris in et at iaculis orci ut netus. Tellus pretium lorem nibh donec. Euismod nulla a e.',
    },
]

export default dataAbout;
