
<template>
    <section class="documents style-2 mobie-pb0" id="document">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="block-text center">
                        <h3>{{ title }}</h3>
                        <p class="mt-12 pd28">{{ subtitle }}</p>
                    </div>
                </div>
            </div>
            <div class="row mt-67">
                <div class="col-xl-3 col-md-6" v-for="item in dataDocument.slice(0, 4)" :key="item.id">
                    <div class="document-box">
                        <div class="card-title" :class="item.classAction">
                            <img :src="item.img" alt="ICOLand" :style="item.imgStyle" />
                            <router-link class="fs-16" to="/document">{{ item.title }}</router-link>
                        </div>
                        <div class="content">
                            <router-link to="/document#" class="title">{{ item.subtitle }}</router-link>
                            <a :href="item.link" class="download" target="_blank" rel="noopener noreferrer">
                                <span class="icon-Group"></span>{{ item.text }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import "./styles.scss";
import { dataDocument } from '../../fake-data/data-document';

export default {
    name: "Feature",
    data() {
        return {
            dataDocument,
            title: 'In The Press',
            subtitle: 'Download our whitepaper and learn about our different events, articles and news coverage of ViZion Protocol',
        }
    }
};
</script>
            
            