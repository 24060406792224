import img1 from '../assets/images/icon/icon.svg';
import img2 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';
import img4 from '../assets/images/icon/icon4.svg';

import img5 from '../assets/images/icon/blockchain.png';
import img6 from '../assets/images/icon/bitcoin.png';
import img7 from '../assets/images/icon/bitcoin2.png';
import img8 from '../assets/images/icon/surface.png';

import img9 from '../assets/images/blog/blog-main-08.jpg';
import img10 from '../assets/images/blog/blog-main-09.jpg';
import img11 from '../assets/images/blog/blog-main-10.jpg';

export const dataFeature = [
    {
        id: 1,
        classAction: 'icon-mobile',
        title: 'iKonX Music App Release (Q1 - 2024)',
        content: `Set to launch in Q1 2024, the IKonX Music App is a groundbreaking platform designed to empower artists. It offers a unique suite of services, enabling them to book collaborations with their idols, discover music labels, connect with managers, and find events, studios, and music festival opportunities. Committed to fostering a supportive ecosystem, iKonX App will contribute 20% of profits to the VIZION liquidity pool every quarter.`,
    },
    {
        id: 2,
        classAction: 'icon-startup',
        title: 'Building The Future w/Blockchain Technology',
        content: `
        ViZion Protocol is at the forefront of innovation, encompassing revolutionary smart contracts, the iconic NFT and Entertainment Marketplace (iKonX), and an expansive DeFi platform. Unique in its offering, it includes a first-of-its-kind utility project, enabling payments for marketing services, events, hotels, trips, music festivals, and more through our exclusive partnerships.`,
    },
    {
        id: 3,
        classAction: 'icon-vuesax',
        title: 'Trust + Security ',
        content: `Our goal has and will always be to provide trust, security and maintain transparency throughout our business model for ViZion Protocol. `,
    },
    {
        id: 4,
        classAction: 'icon-mobile',
        title: 'ViZion NFTs in 2024',
        content: `Launching with iKonX in 2024, artists and musicians will gain the capability to create, sell, and distribute music through NFTs on our platform. iKonX, renowned for its trust and security in the music and entertainment industry, is set to become a pivotal hub for artists looking to launch NFTs, all powered by the robust ViZion Protocol.`,
    },

    {
        id: 5,
        classAction: 'icon-buy',
        title: 'Buy and sell items easily',
        desc1: '- As soon as our artists finish their magnum opus, ',
        desc2: '- The Alpha Kongs Club opens its gates to an exclusive guest',
    },
    {
        id: 6,
        classAction: 'icon-create',
        title: 'Buy and sell items easily',
        desc1: '- As soon as our artists finish their magnum opus, ',
        desc2: '- The Alpha Kongs Club opens its gates to an exclusive guest',
    },
    {
        id: 7,
        classAction: 'icon-chase',
        title: 'Buy and sell items easily',
        desc1: '- As soon as our artists finish their magnum opus, ',
        desc2: '- The Alpha Kongs Club opens its gates to an exclusive guest',
    },
    {
        id: 8,
        classAction: 'icon-brute',
        title: 'Chase limited edition ICOLand',
        desc1: '- As soon as our artists finish their magnum opus, ',
        desc2: '- The Alpha Kongs Club opens its gates to an exclusive guest',
    },
    {
        id: 9,
        classAction: 'icon-bid',
        title: 'Buy and sell items easily',
        desc1: '- As soon as our artists finish their magnum opus, ',
        desc2: '- The Alpha Kongs Club opens its gates to an exclusive guest',
    },
    {
        id: 10,
        classAction: 'icon-bear',
        title: 'Buy and sell items easily',
        desc1: '- As soon as our artists finish their magnum opus, ',
        desc2: '- The Alpha Kongs Club opens its gates to an exclusive guest',
    },

    {
        id: 11,
        classAction: 'icon-icon-f01',
        title: 'Fast Transaction',
        desc: 'Secure transactions between artists and fans/investors. Protecting your ownership.',
    },
    {
        id: 12,
        classAction: 'icon-icon-f02',
        title: 'Connect your crypto',
        desc: 'Connect your crypto wallet and start securely uploading your music NFTs.',
    },
    {
        id: 13,
        classAction: 'icon-icon-f03',
        title: 'Suppport creators',
        desc: 'Support their favourite creators by purchasing editions of their unique music NFTs',
    },
    {
        id: 14,
        classAction: 'icon-icon-f04',
        title: 'Join the community',
        desc: 'User friendly platform where you can, follow, comment and join large communities.',
    },
    {
        id: 15,
        img: img1,
        title: 'Reliable Network',
        desc: 'Use decentralization, trusted nodes,  and premium data.',
    },
    {
        id: 16,
        img: img2,
        title: 'Off-Chain Computation',
        desc: 'Use a decentralized network to automate contracts, mitigating risk',
    },
    {
        id: 17,
        img: img3,
        title: 'Proven Solutions',
        desc: 'Integrate pre-built, time-tested oracle solutions',
    },
    {
        id: 18,
        img: img4,
        title: 'Seamless Connection To API',
        desc: 'Build on a flexible framework that can retrieve data from any API',
    },
    {
        id: 19,
        img: img5,
        title: 'Easily Access to any asset',
        desc: 'Choose your interface, your crypto and stick with it, but still access the entire price',
    },
    {
        id: 20,
        img: img6,
        title: 'Operate dApps from any ',
        desc: 'Feel the power of Universal Apps as you navigate every one of them from your chosen solution.',
    },
    {
        id: 21,
        img: img7,
        title: 'Code with flexibility',
        desc: 'Build on Nervos and have the ability to target any Virtual Machine (VM), port your dApp across ',
    },
    {
        id: 22,
        img: img8,
        title: 'Layer 2 solutions instantly',
        desc: 'Experience scalability in action with working, powerful, Layer 2 scaling solutions. ',
    },

    {
        id: 23,
        classAction: 'icon-security-safe',
        title: 'Reliable, tamper-proof network',
        desc: 'Use decentralization, trusted nodes, premium data, and cryptographic proofs to connect highly accurate and available data/ APIs to any smart contract.',
        action: 'See details',
    },
    {
        id: 24,
        classAction: 'icon-code1',
        title: 'Seamless connection to any API',
        desc: 'Build on a flexible framework that we can retrieve data from any API, connect with existing systems, and integrate with any current or future blockchain.',
        action: 'See details',
    },
    {
        id: 25,
        classAction: 'icon-lamp-charge',
        title: 'Proven, ready-made solutions',
        desc: 'Integrate pre-built, time-tested oracle solutions that already secure tens of billions in smart contract value for market-leading decentralized applications.',
        action: 'See details',
    },
    {
        id: 26,
        classAction: 'icon-key',
        title: 'Secure off-chain computation',
        desc: 'Use a decentralized network of ICOland Keeper nodes to automate contracts, mitigating risk of manual interventions, and a bunch of centralized servers.',
        action: 'See details',
    },

    {
        id: 27,
        img: img9,
        title: 'What Is DeFi? Inside the Wild West of Cryptocurrency.',
        desc: 'Nullam leo urna tortor sodales nisl eget lacus, viverra quisque. ',
    },
    {
        id: 28,
        img: img10,
        title: 'What Is DeFi? Inside the Wild West of Cryptocurrency.',
        desc: 'Nullam leo urna tortor sodales nisl eget lacus, viverra quisque. ',
    },
    {
        id: 29,
        img: img11,
        title: 'What Is DeFi? Inside the Wild West of Cryptocurrency.',
        desc: 'Nullam leo urna tortor sodales nisl eget lacus, viverra quisque. ',
    },
    {
        id: 30,
        img: img9,
        title: 'What Is DeFi? Inside the Wild West of Cryptocurrency.',
        desc: 'Nullam leo urna tortor sodales nisl eget lacus, viverra quisque. ',
    },
    {
        id: 31,
        img: img10,
        title: 'What Is DeFi? Inside the Wild West of Cryptocurrency.',
        desc: 'Nullam leo urna tortor sodales nisl eget lacus, viverra quisque. ',
    },
    {
        id: 32,
        img: img11,
        title: 'What Is DeFi? Inside the Wild West of Cryptocurrency.',
        desc: 'Nullam leo urna tortor sodales nisl eget lacus, viverra quisque. ',
    },
]

export default dataFeature;