
// import img2 from '../assets/images/layout/roadmap02.png';
// import img3 from '../assets/images/layout/roadmap03.png';
// import img4 from '../assets/images/layout/roadmap04.png';

// import img5 from '../assets/images/icon/iconroadmap1.svg';
// import img6 from '../assets/images/icon/iconroadmap2.svg';

export const dataRoadMap = [
  {
    id: 1,
    classAction: "bottom",
    classIcon1: "icon-gene",
    classIcon2: "icon-gene",
    title1: "2022",
    title: "Our ViZion ",
    list1: "Created Company + Legal Structure",
    list2: "Built Team, Advisors + Partnerships",
    classAction3: "mb0",
    classAction4: "none",
  },
  {
    id: 2,
    classAction: "top",
    title1: "2023",
    title2: "Q1",
    title: "Built Trust Within Community",
    list1: "Financed Feature Film thru ViZion ",
    list2: "Won SXSW Film Festival",
    classIcon1: "icon-boxs",
    classIcon2: "icon-gene",

  },
  {
    id: 3,
    classAction: "bottom",
    title1: "2023",
    title2: "Q2",
    title: "Proof of Work",
    list1: "Sponsored Multiple Events",
    list2: "B*tch Ass NFT Launched",
    classIcon1: "icon-boxs",
    classIcon2: "icon-gene",
    classAction3: "mb0",
    classAction4: "none",
  },
  {
    id: 5,
    classAction: "bottom",
    title1: "2023",
    title2: "Q3",
    title: "ViZion Utility",
    list1:
      "Events, Services + More Products Releases",
    list2:
      "Several Exchanges Added",
    list3:
      " Continue to Build ViZion Eco System.",
    classIcon1: "icon-boxs",
    classIcon2: "icon-gene",
    classIcon3: "icon-gene",
  },
  {
    id: 6,
    classAction: "top",
    title1: "2024",
    title2: "Q1",
    title: "OFFICIAL LAUNCH",
    list1: "VIZION will be available on DEX and various exchanges.",
    list2:
      "To mitigate volatility, tokens will be released monthly to early holders",
    classIcon1: "icon-boxs",
    classIcon2: "icon-gene",
    classAction3: "mb0",
    classAction4: "none",
  },
  
];

export default dataRoadMap;


// {
//   id: 7,
//   classAction: "bottom",
//   title1: "2024",
//   title2: "Q2",
//   title: "iKonX Music App Release",
//   list1:
//     "First of a kind, all in one platform for the entire music, film and entertainment industry. ",
//   list2:
//     "Showcasing all of the true utility behind ViZion Protocol and what we are building for the future of blockchain.",
//   classIcon1: "icon-boxs",
//   classIcon2: "icon-gene",
//   classAction3: "mb0",
//   classAction4: "none",
// },
// {
//   id: 9,
//   classAction: "s2",
//   title: "Level 02",
//   title2: "  - Kongz Utility Expansion ",
//   desc: "The time for you to get your own CyberKongz VX as a custom playable Metaverse Avatar has finally arrived. CyberKongz VX are launched in partnership with The Sandbox and the integration as playable avatars into The Sandbox game is now in development.",
//   img: img2,
// },
// {
//   id: 10,
//   title: "Level 03",
//   title2: " - Kongz Island ",
//   desc: "The time for you to get your own CyberKongz VX as a custom playable Metaverse Avatar has finally arrived. CyberKongz VX are launched in partnership with The Sandbox and the integration as playable avatars into The Sandbox game is now in development.",
//   img: img3,
// },
// {
//   id: 11,
//   classAction: "s2",
//   title: "Level 04",
//   title2: " - The Banana Shop",
//   desc: "The time for you to get your own CyberKongz VX as a custom playable Metaverse Avatar has finally arrived. CyberKongz VX are launched in partnership with The Sandbox and the integration as playable avatars into The Sandbox game is now in development.",
//   img: img4,
// },

// {
//   id: 12,
//   time: "2022 -Q1",
//   title: "THE GRAN OPENING",
//   desc1:
//     "As soon as our artists finish their magnum opus, paying careful attention to every last detail… guest list of 8,888 visionaries. Each member carries ",
//   desc2:
//     "The quality of these digital membership cards will be up to par with modern art collections. As you’ll see, our artists’ eye for detail is unmatched by anyone in the NFT space.",
//   title2: "THEIR OWN UNIQUE BLUE CHIP NFT.",
// },
// {
//   id: 13,
//   time: "2022 -Q2",
//   title: "GIVING BACK TO THE OG KONGS",
//   desc1:
//     "As soon as our artists finish their magnum opus, paying careful attention to every last detail… guest list of 8,888 visionaries. Each member carries ",
//   desc2:
//     "The quality of these digital membership cards will be up to par with modern art collections. As you’ll see, our artists’ eye for detail is unmatched by anyone in the NFT space.",
//   title2: "THEIR OWN UNIQUE BLUE CHIP NFT.",
// },
// {
//   id: 14,
//   time: "2022 -Q3",
//   title: "REVEAL THE LEGENDARIES",
//   desc1:
//     "As soon as our artists finish their magnum opus, paying careful attention to every last detail… guest list of 8,888 visionaries. Each member carries ",
//   desc2:
//     "The quality of these digital membership cards will be up to par with modern art collections. As you’ll see, our artists’ eye for detail is unmatched by anyone in the NFT space.",
//   title2: "THEIR OWN UNIQUE BLUE CHIP NFT.",
// },
// {
//   id: 15,
//   time: "2022 -Q4",
//   title: "REVEAL THE LEGENDARIES",
//   desc1:
//     "As soon as our artists finish their magnum opus, paying careful attention to every last detail… guest list of 8,888 visionaries. Each member carries ",
//   desc2:
//     "The quality of these digital membership cards will be up to par with modern art collections. As you’ll see, our artists’ eye for detail is unmatched by anyone in the NFT space.",
//   title2: "THEIR OWN UNIQUE BLUE CHIP NFT.",
// },
// {
//   id: 16,
//   time: "April 15, 2019",
//   classAction: "left active",
//   title: "Concept",
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//   ],
// },
// {
//   id: 17,
//   classAction: "right active",
//   time: "April 20, 2019",
//   title: "Research",
//   list: [
//     {
//       id: 1,
//       text: "Proving the concept can work",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },
// {
//   id: 18,
//   classAction: "left",
//   time: "April 15, 2019",
//   title: "Concept",
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//   ],
// },
// {
//   id: 19,
//   classAction: "right",
//   time: "April 20, 2019",
//   title: "Research",
//   list: [
//     {
//       id: 1,
//       text: "Proving the concept can work",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },
// {
//   id: 20,
//   classAction: "left",
//   time: "April 15, 2019",
//   title: "Concept",
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//   ],
// },
// {
//   id: 21,
//   classAction: "right",
//   time: "April 20, 2019",
//   title: "Research",
//   list: [
//     {
//       id: 1,
//       text: "Proving the concept can work",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },

// {
//   id: 22,
//   classAction: "active",
//   time: "April 15, 2019",
//   title: "Concept",
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//   ],
// },
// {
//   id: 23,
//   classAction: "active",
//   time: "April 20, 2019",
//   title: "Research",
//   list: [
//     {
//       id: 1,
//       text: "Proving the concept can work",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },
// {
//   id: 24,
//   time: "April 15, 2019",
//   title: "Design",
//   list: [
//     {
//       id: 1,
//       text: "Platform design",
//     },
//     {
//       id: 2,
//       text: "Building the MVP",
//     },
//   ],
// },
// {
//   id: 25,
//   time: "July 01, 2019",
//   title: "Pre-sale",
//   list: [
//     {
//       id: 1,
//       text: "Public financing & Seed funding raised",
//     },
//   ],
// },
// {
//   id: 26,
//   time: "April 15, 2019",
//   title: "Concept",
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//   ],
// },
// {
//   id: 27,
//   time: "April 20, 2019",
//   title: "Research",
//   list: [
//     {
//       id: 1,
//       text: "Proving the concept can work",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },

// {
//   id: 28,
//   title: "Q1 2021",
//   list: [
//     {
//       id: 1,
//       text: "Release website and logo",
//     },
//     {
//       id: 2,
//       text: "Grow community",
//     },
//     {
//       id: 3,
//       text: "Launch the project",
//     },
//     {
//       id: 4,
//       text: "Social media setup",
//     },
//   ],
// },
// {
//   id: 29,
//   title: "Q2 2021",
//   list: [
//     {
//       id: 1,
//       text: "Release website and logo",
//     },
//     {
//       id: 2,
//       text: "Grow community",
//     },
//     {
//       id: 3,
//       text: "Launch the project",
//     },
//     {
//       id: 4,
//       text: "Social media setup",
//     },
//   ],
// },
// {
//   id: 30,
//   title: "Q3 2021",
//   list: [
//     {
//       id: 1,
//       text: "Release website and logo",
//     },
//     {
//       id: 2,
//       text: "Grow community",
//     },
//     {
//       id: 3,
//       text: "Launch the project",
//     },
//     {
//       id: 4,
//       text: "Social media setup",
//     },
//   ],
// },
// {
//   id: 31,
//   title: "Q4 2021",
//   list: [
//     {
//       id: 1,
//       text: "Release website and logo",
//     },
//     {
//       id: 2,
//       text: "Grow community",
//     },
//     {
//       id: 3,
//       text: "Launch the project",
//     },
//     {
//       id: 4,
//       text: "Social media setup",
//     },
//   ],
// },

// {
//   id: 32,
//   classAction: "right active",
//   title: "Q1 2021",
//   title2: "Concept",
//   img: img5,
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//     {
//       id: 3,
//       text: "Proving the concept",
//     },
//   ],
// },
// {
//   id: 33,
//   classAction: "left active",
//   title: "Q2 2021",
//   title2: "Research",
//   img: img6,
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },
// {
//   id: 34,
//   classAction: "right",
//   title: "Q3 2021",
//   title2: "Design",
//   img: img5,
//   list: [
//     {
//       id: 1,
//       text: "Platform design and technical demonstration",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//     {
//       id: 3,
//       text: "Proving the concept",
//     },
//   ],
// },
// {
//   id: 35,
//   classAction: "left",
//   title: "Q4 2021",
//   title2: "Research",
//   img: img6,
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },
// {
//   id: 36,
//   classAction: "right",
//   title: "2022",
//   title2: "Design",
//   img: img5,
//   list: [
//     {
//       id: 1,
//       text: "Public fiancing & Seed funding raised",
//     },
//     {
//       id: 2,
//       text: "ICO Press Tour",
//     },
//     {
//       id: 3,
//       text: "Open global sales token",
//     },
//   ],
// },
// {
//   id: 37,
//   classAction: "top",
//   title: "April 15, 2019",
//   title2: "Concept",
//   list: [
//     {
//       id: 1,
//       text: "Concept Generation",
//     },
//     {
//       id: 2,
//       text: "Team Assemble",
//     },
//   ],
// },
// {
//   id: 38,
//   classAction: "bottom",
//   title: "April 20, 2019",
//   title2: "Research",
//   list: [
//     {
//       id: 1,
//       text: "Proving the concept can work",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },
// {
//   id: 39,
//   classAction: "top",
//   title: "April 15, 2019",
//   title2: "Design",
//   list: [
//     {
//       id: 1,
//       text: "Platform design",
//     },
//     {
//       id: 2,
//       text: "Building the MVP",
//     },
//   ],
// },
// {
//   id: 40,
//   classAction: "bottom",
//   title: "July 01, 2019",
//   title2: "Pre-sale",
//   list: [
//     {
//       id: 1,
//       text: "Public financing & Seed funding raised",
//     },
//   ],
// },
// {
//   id: 41,
//   classAction: "top",
//   title: "Oct 01, 2019",
//   title2: "App Beta Test",
//   list: [
//     {
//       id: 1,
//       text: "Private closed beta",
//     },
//     {
//       id: 2,
//       text: "Open beta launched to public and improvement the app",
//     },
//   ],
// },
// {
//   id: 42,
//   classAction: "bottom",
//   title: "Nov 03, 2019",
//   title2: "ConcToken Saleept",
//   list: [
//     {
//       id: 1,
//       text: "ICO press tour",
//     },
//     {
//       id: 2,
//       text: "Open global sales of ICOblock token",
//     },
//   ],
// },
// {
//   id: 43,
//   classAction: "top",
//   title: "10 Jan, 2020",
//   title2: "Alpha Test",
//   list: [
//     {
//       id: 1,
//       text: "In-house testing of functional",
//     },
//     {
//       id: 2,
//       text: "Prototype published a",
//     },
//   ],
// },
// {
//   id: 44,
//   classAction: "bottom",
//   title: "April 20, 2019",
//   title2: "Research",
//   list: [
//     {
//       id: 1,
//       text: "Proving the concept can work",
//     },
//     {
//       id: 2,
//       text: "White paper conpletion",
//     },
//   ],
// },
// {
//   id: 45,
//   classAction: "top",
//   title: "Oct 01, 2019",
//   title2: "App Beta Test",
//   list: [
//     {
//       id: 1,
//       text: "Private closed beta",
//     },
//     {
//       id: 2,
//       text: "Open beta launched to public and improvement the app",
//     },
//   ],
// },