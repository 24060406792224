export const dataTokendetails = [
    {
        id: 1,
        classAction: 'active',
        title: 'Start',
        desc: 'Dec 22, 2023 (12:00AM EST)',
    },
    {
        id: 2,
        title: 'End',
        desc: 'Jan 07, 2024 (11:59 PM EST)',
    },
    {
        id: 3,
        title: 'Utility Tokens Available ',
        desc: '7,000,000 Tokens',
    },
    {
        id: 4,
        title: 'Token Amounts Available',
        desc: '$1,000 $2,500 $5,000 $10,000'
    },

    {
        id: 5,
        title: 'Limited Spots Remaining ',
        desc: 'Only Accepting Cryptocurrency',
    },
    {
        id: 6,
        title: 'Acceptable curencies',
        desc: 'BTC, ETH, SOL, USDC',
    },

    {
        id: 7,
        title: 'VIZION TOKEN DISTRIBUTION',
        desc: `Bronze 20,000, Silver 55,555,
        Gold 125,000, Platinum 333,333`
    },




]

export default dataTokendetails;


// {
//     id: 7,
//     title: 'Public Sale Starts',
//     desc: '28 JAN 2022 12:00 GMT',
// },
// {
//     id: 8,
//     title: 'Publlic Sale Ends',
//     desc: '14TH FEB 2022 12:00 GMT',
// },
// {
//     id: 9,
//     title: 'Total Token Sale',
//     desc: '1,550,000',
// },
// {
//     id: 10,
//     title: 'Token Exchange Rate',
//     desc: '1 BTC = 1558 CLO',
// },
// {
//     id: 11,
//     title: 'Token Allocated',
//     desc: '880,000',
// },
// {
//     id: 12,
//     title: 'Acceptable Currency',
//     desc: 'BTC, ETH, LTC',
// },

// {
//     id: 13,
//     classAction: 'active',
//     title: 'Start',
//     desc: 'Aug 8, 2021 (9:00AM GMT)',
// },
// {
//     id: 14,
//     title: 'End',
//     desc: 'Feb 10, 2022 (9:00AM GMT)',
// },
// {
//     id: 15,
//     title: 'Acceptable Curencies',
//     desc: 'ETH, BTC, LTC',
// },
// {
//     id: 16,
//     title: 'Number Of Tokens For Sale',
//     desc: '1.000.000 Tokens',
// },
// {
//     id: 17,
//     title: 'Tokens Exchange Rate',
//     desc: '1 ETH = 650 ICC, 1 BTC = 1940 ICC',
// },
// {
//     id: 18,
//     title: 'Minimal Transaction',
//     desc: '10 Tokens / Transaction',
// },